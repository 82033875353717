import React from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
export interface TestBlockForCatalogType {
  universityName: string;
  programName: string;
  img: number | null;
  universityIcon: number | null;
  priceFrom: string;
  priceTo: string;
  description: string;
  levels: {
    name: string
  }[];
  formats?: {
    name: string
  }[];
  period: string;
  place: string
}

export const TestBlockForCatalog = () => {
  return (
    <></>
  );
};

export const TestBlockForCatalogInfo: TypedBlockTypeInfo<TestBlockForCatalogType> = {
  id: "testBlockForCatalog",
  name: "TestBlockForCatalog",
  preview: preview,
  renderer: TestBlockForCatalog,
  initialData: {
    universityName: "string",
    programName: "string",
    img: null,
    universityIcon: null,
    priceFrom: "string",
    priceTo: "string",
    description: "string",
    levels: [{
      name: "Bachelor"
    }],
    formats: [{
      name: "remote"
    }],
    period: "4 years",
    place: "On campus"
  },
  definition: {
    subTypes: {
      level: {
        fields: [
          {
            id: "name",
            type: "String",
            name: "Name"
          },
        ]
      },
      format: {
        fields: [
          {
            id: "name",
            type: "String",
            name: "Name"
          },
        ]
      }
    },
    fields: [
      {
        id: "universityName",
        name: "University Name",
        type: "String",
      },
      {
        id: "programName",
        name: "Program Name",
        type: "String",
      },
      {
        id: "img",
        name: "Image",
        type: "Custom",
        customType: "Image"
      },
      {
        id: "universityIcon",
        name: "University Icon",
        type: "Custom",
        customType: "Image"
      },
      {
        id: "priceFrom",
        name: "Price From",
        type: "String",
      },
      {
        id: "priceTo",
        name: "Price To",
        type: "String",
      },
      {
        id: "description",
        name: "Description",
        type: "String",
      },
      {
        id: "levels",
        type: "List",
        listType: "level",
        name: "Levels"
      },
      {
        id: "formats",
        type: "List",
        listType: "format",
        name: "Formats"
      },
      {
        id: "period",
        name: "Period",
        type: "String",
      },
      {
        id: "place",
        name: "Place",
        type: "String",
      },
    ],
  },
};
