import React, { useRef } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./SuccessStoriesBlock.module.css";
import { ApiBaseUrl } from "../../api/apiClientBase";
import preview from "./preview.png";
import Arrow from "../../assets/icons/arrow.svg";
import cn from "classnames";
import { Slider } from "../../ui/Slider/Slider";

export interface SuccessStoriesBlockElement {
  title: string;
  text: string;
  highlightedText: string;
  elements: {
    videoLink: string;
    name: string;
    backgroundImg: number | null;
    description: string;
    logo: number | null;
    review?: string;
    tag: string;
  }[];
}

export const SuccessStoriesBlock = ({ title, text, highlightedText, elements }: SuccessStoriesBlockElement) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 260;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 260;
    }
  };

  const textBefore = text ? text.slice(0, text.indexOf(highlightedText)) : "";
  const textAfter = text
    ? textBefore.length !== text.length
      ? text.slice(text.indexOf(highlightedText) + highlightedText.length)
      : ""
    : "";

  return (
    <div className="py-12">
      <div className={styles.successStoriesBlock__title + " text-center md:mb-6 mb-3"}>{title}</div>
      <div className={styles.successStoriesBlock__text + " text-center md:mb-6 mb-3"}>
        {textBefore}
        {highlightedText && <span>{highlightedText}</span>}
        {textAfter}
      </div>
      <div className={"hidden lg:flex gap-5 justify-center mb-6 mt-6 mb-0"}>
        <button onClick={scrollLeft}>
          <img src={Arrow} alt="left button" />
        </button>
        <button onClick={scrollRight} className="transform rotate-180">
          <img src={Arrow} alt="right button" />
        </button>
      </div>
      <div
        ref={scrollContainerRef} className={cn("hidden md:flex justify-start max-w-screen-xl gap-8" +
        " w-full my-0 mx-auto py-6 px-2 md:px-8 overflow-x-auto", styles.scrollContainer)}
      >
        {elements &&
          elements.map(({ name, videoLink, backgroundImg, description, logo, review, tag }, index) => (
            <div
              className={cn(styles.successStoriesBlock__card, videoLink && styles.successStoriesBlock__cardWithVideo, backgroundImg && styles.successStoriesBlock__cardWithBackground)}
              key={index}
              style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}
            >
              {videoLink && (
                <iframe
                  src={videoLink}
                  allow="autoplay; encrypted-media; screen-wake-lock"
                  allowFullScreen
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                ></iframe>
              )}
              <div>
                <h3 className={styles.successStoriesBlock__name}>{name}</h3>
                <p className={styles.successStoriesBlock__description}>{description}</p>
              </div>
              <div>
                {logo && (
                  <img
                    src={`${ApiBaseUrl}/api/media/${logo}`}
                    className={styles.successStoriesBlock__cardImage}
                    alt="logo"
                  />
                )}
                <p className={styles.successStoriesBlock__review}>{review}</p>
                <span className={styles.successStoriesBlock__tag}>{tag}</span>
              </div>
            </div>
          ))}
      </div>
      <div className={cn("flex my-8 mx-auto md:hidden")}>
        <Slider backgroundColor="white">
          {elements.map(({ name, videoLink, backgroundImg, description, logo, review, tag }, index) => (
            <div
              className={cn(
                styles.successStoriesBlock__card,
                backgroundImg && styles.successStoriesBlock__cardWithBackground
              )}
              key={index}
              style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${backgroundImg})` }}
            >
              {videoLink && (
                <iframe
                  src={videoLink}
                  allow="autoplay; encrypted-media; screen-wake-lock"
                  allowFullScreen
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                ></iframe>
              )}
              <div>
                <h3 className={styles.successStoriesBlock__name}>{name}</h3>
                <p className={styles.successStoriesBlock__description}>{description}</p>
              </div>
              <div>
                {logo && (
                  <img
                    src={`${ApiBaseUrl}/api/media/${logo}`}
                    className={styles.successStoriesBlock__cardImage}
                    alt="logo"
                  />
                )}
                <p className={styles.successStoriesBlock__review}>{review}</p>
                <span className={styles.successStoriesBlock__tag}>{tag}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export const SuccessStoriesBlockInfo: TypedBlockTypeInfo<SuccessStoriesBlockElement> = {
  id: "SuccessStoriesBlock",
  name: "SuccessStoriesBlock",
  preview: preview,
  renderer: SuccessStoriesBlock,
  initialData: {
    title: "string",
    text: "this is string, not a number",
    highlightedText: "string",
    elements: [
      {
        name: "string",
        description: "string",
        backgroundImg: null,
        videoLink: "",
        logo: null,
        tag: "string",
      },
    ],
  },
  definition: {
    subTypes: {
      element: {
        fields: [
          {
            id: "name",
            type: "String",
            name: "Name",
          },
          {
            id: "videoLink",
            type: "String",
            name: "Video Link",
          },
          {
            id: "backgroundImg",
            name: "Background Image",
            type: "Custom",
            customType: "Image",
          },
          {
            id: "description",
            type: "String",
            name: "Description",
          },
          {
            id: "logo",
            type: "Custom",
            customType: "Image",
            name: "Logo",
          },
          {
            id: "review",
            type: "String",
            name: "Review",
          },
          {
            id: "tag",
            type: "String",
            name: "Tag",
          },
        ],
      },
    },
    fields: [
      {
        id: "title",
        type: "String",
        name: "Title",
      },
      {
        id: "text",
        type: "String",
        name: "Text",
      },
      {
        id: "highlightedText",
        type: "String",
        name: "Highlighted Text",
      },
      {
        id: "elements",
        type: "List",
        listType: "element",
        name: "Elements",
      },
    ],
  },
};
