import React from "react";
import { BasicHtmlBlockInfo } from "./BasicHtmlBlock/basic-html";
import { CirclesBlockInfo } from "./CirclesBlock/circles";
import { ReadMoreBlockInfo } from "./ReadMoreBlock/readMoreBlock";
import { ReasonsAcceptIconBlockInfo } from "./ReasonsAcceptIconBlock/reasonsAcceptIconBlock";
import { ReasonsCustomIconBlockInfo } from "./ReasonsCustomIconBlock/reasonsCustomIconBlock";
import { LeftAlignBlockInfo } from "./LeftAlignBlock/leftAlignBlock";
import { ArticleBeforeImageBlockInfo } from "./ArticleBeforeImageBlock/articleBeforeImageBlock";
import { BigCardsListBlockInfo } from "./BigCardsListBlock/bigCardsListBlock";
import { ButtonFormBlockInfo } from "./ButtonFormBlock/buttonFormBlock";
import { ImageBlockInfo } from "./ImageBlock/imageBlock";
import { ReasonsAcceptCardBlockInfo } from "./ReasonsAcceptCardBlock/reasonsAcceptCardBlock";
import { CardsWithLinksBlockInfo } from "./CardsWithLinksBlock/cardsWithLinksBlock";
import { GoogleMapBlockInfo } from "./GoogleMapBlock/googleMapBlock";
import { BlockTypeInfo } from "./blocks-info";
import { PageBlockRowDto } from "../interfaces/pageSharedDto";
import { TabControlBlockInfo } from "./TabControlBlock/TabControlBlock";
import { TitleAndTabsBlockInfo } from "./TitleAndTabsBlock/titleAndTabsBlock";
import { BreadcrumbsBlockInfo } from "./BreadcrumbsBlock/breadcrumbsBlock";
import { TitleAndCallBackBlockInfo } from "./TitleAndCallBackBlock/titleAndCallBackBlock";
import { ImgAndTextEditorBlockInfo } from "./ImgAndTextEditorBlock/imgAndTextEditorBlock";
import { LeftTabsRightContentBlockInfo } from "./LeftTabsRightContentBlock/LeftTabsRightContentBlock";
import { FeedbackSliderBlockInfo } from "./FeedbackSliderBlock/feedbackSliderBlock";
import { StringReasonsBlockInfo } from "./StringReasonsBlock/stringReasonsBlock";
import { FaqBlockInfo } from "./FaqBlock/faqBlock";
import { HtmlWithIconBlockInfo } from "./HtmlWithIconBlock/htmlWithIconBlock";
import { FramesWithArrowBlockInfo } from "./FramesWithArrowBlock/framesWithArrowBlock";
import { FixedHeightBlockInfo } from "./FixedHeightBlock/fixedHeightBlock";
import { TableBlockInfo } from "./TableBlock/TableBlock";
import { NewsletterBlockInfo } from "./NewsletterBlock/NewsletterBlock";
import { HistoryClientsBlockInfo } from "./Blog/HistoryClients/historyClientsBlock";
import { FirstArticleBlockInfo } from "./Blog/FirstArticle/firstArticleBlock";
import { NewTitleAndTabsBlockInfo } from "./NewTitleAndTabsBlock/newTitleAndTabsBlock";
import { NewBreadcrumbsBlockInfo } from "./NewBreadcrumbsBlock/newBreadcrumbsBlock";
import { MoreAboutCompanyBlockInfo } from "./MoreAboutCompanyBlock/MoreAboutCompanyBlock";
import { LevelTestBlockInfo } from "./LevelTestBlock/LevelTestBlock";

import cn from "classnames";
import grid from "../styles/grid.module.css";
import { PartnersBlockInfo } from "./PartnersBlock/PartnersBlock";
import { NewsBlockInfo } from "./NewsBlock/NewsBlock";
import { SocialBlockInfo } from "./SocialBlock/SocialBlock";
import { GallerySliderBlockInfo } from "./GallerySliderBlock/gallerySliderBlock";
import { CommentsBlockInfo } from "./CommentsBlock/commentsBlock";
import { SkipHistoryBlockInfo } from "./SkipHistoryBlock/skip-history";
import { MultiImgBlockInfo } from "./MultiImgBlock/MultiImgBlock";
import { RequestFormDto } from "admin-app/src/interfaces/GlobalSettingsDto";
import { FormBuilderBlockList } from "../FormBuilderBlocks/FormBuilderBlockList";
import { NewButtonFormBlockInfo } from "./NewButtonFormBlock/newButtonFormBlock";
import { NewFounderBlockInfo } from "./NewFounderBlock/newFounderBlock";
import { SocialNetworkBlockInfo } from "./SocialNetworkBlock/SocialNetworkBlock";
import { PopularServiceBlockInfo } from "./PopularServicesBlock/PopularServiceBlock";
import { UsefulArticlesBlockInfo } from "./UsefulArticlesBlock/UsefulArticlesBlock";
import { NewPartnersBlockInfo } from "./NewPartnersBlock/NewPartnersBlock";
import { SuccessStoriesBlockInfo } from "./SuccessStoriesBlock/SuccessStoriesBlock";
import { RequestForConsultationBlockInfo } from "./RequestForConsultationBlock/RequestForConsultationBlock";
import { EducationalDirectionsBlockInfo } from "./EducationalDirectionsBlock/EducationalDirectionsBlock";
import { ServiceBlockInfo } from "./ServiceBlock/ServiceBlock";
import { PaymentForServicesBlockInfo } from "./PaymentForServicesBlock/PaymentForServicesBlock";
import { AdditionalInformationBlockInfo } from "./AdditionalInformationBlock/AdditionalInformationBlock";
import { QuestionAndAnswerBlockInfo } from "./QuestionAndAnswerBlock/QuestionAndAnswerBlock";
import { AboutCompanyBlockInfo } from "./AboutCompanyBlock/AboutCompanyBlock";
import { HistoryBlockInfo } from "./HistoryBlock/HistoryBlock";
import { TextAndImageBlockInfo } from "./TextAndImageBlock/TextAndImageBlock";
import { OurWorkBlockInfo } from "./OurWorkBlock/OurWorkBlock";
import { WorkBasisBlockInfo } from "./WorkBasisBlock/WorkBasisBlock";
import { CompanyDescriptionBlockInfo } from "./CompanyDescriptionBlock/CompanyDescriptionBlock";
import { AdvantagesBlockInfo } from "./AdvantagesBlock/AdvantagesBlock";
import { SolidTeamBlockInfo } from "./SolidTeamBlock/SolidTeamBlock";
import { ServicesIntroBlockInfo } from "./ServicesIntroBlock/ServicesIntroBlock";
import { MoreServicesBlockInfo } from "./MoreServicesBlock/MoreServicesBlock";
import { AdditionalServicesBlockInfo } from "./AdditionalServicesBlock/AdditionalServicesBlock";
import { LineAndImageBlockInfo } from "./LineAndImageBlock/LineAndImageBlock";
import { HelpIntroBlockInfo } from "./HelpIntroBlock/HelpIntroBlock";
import { AdditionalServiceBlockInfo } from "./AdditionalServiceBlock/AdditionalServiceBlock";
import { AboutServiceBlockInfo } from "./AboutServiceBlock/AboutServiceBlock";
import { ListServiceBlockInfo } from "./ListServiceBlock/ListServiceBlock";
import { ResultsWorkBlockInfo } from "./ResultsWorkBlock/ResultsWorkBlock";
import { AdditionalServicesListBlockInfo } from "./AdditionalServicesListBlock/AdditionalServicesListBlock";
import { ForWhomBlockInfo } from "./ForWhomBlock/ForWhomBlock";
import { ProgramsBlockInfo } from "./ProgramsBlock/ProgramsBlock";
import { StudyPlanBlockInfo } from "./StudyPlanBlock/StudyPlanBlock";
import { HabitationBlockInfo } from "./HabitationBlock/HabitationBlock";
import { RequirementsListBlockInfo } from "./RequirementsListBlock/RequirementsListBlock";
import { RatingsBlockInfo } from "./RatingsBlock/RatingsBlock";
import { OffersBlockInfo } from "./OffersBlock/OffersBlock";
import { IntroEducationalBlockInfo } from "./IntroEducationalBlock/IntroEducationalBlock";
import { IconAndImageBlockInfo } from "./IconAndImageBlock/IconAndImageBlock";
import { PhotoGalleryBlockInfo } from "./PhotoGalleryBlock/PhotoGalleryBlock";
import { TrainingPlanBlockInfo } from "./TrainingPlanBlock/TrainingPlanBlock";
import { CostBlockInfo } from "./CostBlock/CostBlock";
import { RecommendationsBlockInfo } from "./RecommendationsBlock/RecommendationsBlock";
import { ConsultationWithTextBlockInfo } from "./ConsultationWithTextBlock/ConsultationWithTextBlock";
import { GalleryTeachersBlockInfo } from "./GalleryTeachersBlock/GalleryTeachersBlock";
import { LocationBlockInfo } from "./LocationBlock/LocationBlock";
import { RequisitesBlockInfo } from "./RequisitesBlock/RequisitesBlock";
import { ContactInfoBlockInfo } from "./ContactInfoBlock/ContactInfoBlock";
import { KnowledgeBaseArticleFirstBlockInfo } from "./KnowledgeBase/KnowledgeBaseArticleFirstBlock";
import { ServicePackagesBlockInfo } from "./ServicePackagesBlock/ServicePackagesBlock";
import {StatisticsBlockInfo} from "./StatisticsBlock/StatisticsBlock";
import {AcademicSupportBlockInfo} from "./AcademicSupportBlock/AcademicSupportBlock";
import { ImgAndSignatureBlockInfo } from "./ImgAndSignatureBlock/ImgAndSignatureBlock";
import { ImgAndSignatureAndTextBlockInfo } from "./ImgAndSignatureAndTextBlock/ImgAndSignatureAndTextBlock";
import { SubscriptionBlockInfo } from "./SubscriptionBlock/SubscriptionBlock";
import {ProgramsListBlockInfo} from "./ProgramsListBlock/ProgramsListBlock";
import {AwardsBlockInfo} from "./AwardsBlock/AwardsBlock";
import {TestBlockForCatalogInfo} from "./TestBlockForCatalog/TestBlockForCatalog";
import { TableContentsBlockInfo } from "./TableContentsBlock/TableContentsBlock";
import { SelectedBlockInfo } from "./SelectedBlock/SelectedBlock";
import { BlueTextBlockInfo } from "./BlueTextBlock/BlueTextBlock";
import { AuthorBlockInfo } from "./AuthorBlock/AuthorBlock";

export const AvailableBlocks: BlockTypeInfo[] = [
  CirclesBlockInfo,
  BasicHtmlBlockInfo,
  ReadMoreBlockInfo,
  ReasonsAcceptIconBlockInfo,
  ReasonsCustomIconBlockInfo,
  LeftAlignBlockInfo,
  ArticleBeforeImageBlockInfo,
  BigCardsListBlockInfo,
  ButtonFormBlockInfo,
  ImageBlockInfo,
  ReasonsAcceptCardBlockInfo,
  CardsWithLinksBlockInfo,
  TabControlBlockInfo,
  TitleAndTabsBlockInfo,
  BreadcrumbsBlockInfo,
  TitleAndCallBackBlockInfo,
  ImgAndTextEditorBlockInfo,
  LeftTabsRightContentBlockInfo,
  GoogleMapBlockInfo,
  FeedbackSliderBlockInfo,
  StringReasonsBlockInfo,
  FaqBlockInfo,
  HtmlWithIconBlockInfo,
  TableBlockInfo,
  FramesWithArrowBlockInfo,
  FixedHeightBlockInfo,
  PartnersBlockInfo,
  NewsBlockInfo,
  SocialBlockInfo,
  GallerySliderBlockInfo,
  NewsletterBlockInfo,
  HistoryClientsBlockInfo,
  FirstArticleBlockInfo,
  CommentsBlockInfo,
  SkipHistoryBlockInfo,
  MultiImgBlockInfo,
  NewTitleAndTabsBlockInfo,
  NewBreadcrumbsBlockInfo,
  NewButtonFormBlockInfo,
  NewFounderBlockInfo,
  MoreAboutCompanyBlockInfo,
  LevelTestBlockInfo,
  SocialNetworkBlockInfo,
  PopularServiceBlockInfo,
  UsefulArticlesBlockInfo,
  NewPartnersBlockInfo,
  RequestForConsultationBlockInfo,
  EducationalDirectionsBlockInfo,
  SuccessStoriesBlockInfo,
  ServiceBlockInfo,
  PaymentForServicesBlockInfo,
  AdditionalInformationBlockInfo,
  QuestionAndAnswerBlockInfo,
  AboutCompanyBlockInfo,
  HistoryBlockInfo,
  TextAndImageBlockInfo,
  OurWorkBlockInfo,
  CompanyDescriptionBlockInfo,
  WorkBasisBlockInfo,
  AdvantagesBlockInfo,
  SolidTeamBlockInfo,
  ServicesIntroBlockInfo,
  MoreServicesBlockInfo,
  AdditionalServicesBlockInfo,
  LineAndImageBlockInfo,
  HelpIntroBlockInfo,
  AdditionalServiceBlockInfo,
  AboutServiceBlockInfo,
  ListServiceBlockInfo,
  ResultsWorkBlockInfo,
  AdditionalServicesListBlockInfo,
  ForWhomBlockInfo,
  ProgramsBlockInfo,
  StudyPlanBlockInfo,
  HabitationBlockInfo,
  RequirementsListBlockInfo,
  RatingsBlockInfo,
  OffersBlockInfo,
  IntroEducationalBlockInfo,
  IconAndImageBlockInfo,
  PhotoGalleryBlockInfo,
  TrainingPlanBlockInfo,
  CostBlockInfo,
  RecommendationsBlockInfo,
  ConsultationWithTextBlockInfo,
  GalleryTeachersBlockInfo,
  LocationBlockInfo,
  RequisitesBlockInfo,
  ContactInfoBlockInfo,
  KnowledgeBaseArticleFirstBlockInfo,
  ServicePackagesBlockInfo,
  StatisticsBlockInfo,
  AcademicSupportBlockInfo,
  ImgAndSignatureBlockInfo,
  ImgAndSignatureAndTextBlockInfo,
  SubscriptionBlockInfo,
  ProgramsListBlockInfo,
  AwardsBlockInfo,
  TestBlockForCatalogInfo,
  TableContentsBlockInfo,
  SelectedBlockInfo,
  BlueTextBlockInfo,
  AuthorBlockInfo,
];

export interface IComponentHost {
  showContactUsForm: () => void;
  showContactUsFormServiceBlue: () => void;
  showContactUsFormServicePink: () => void;
  showContactUsFormBlue: () => void;
  showContactUsFormPackage: () => void;
  hideModal: () => void;
  filters: any;
  lang: string;
  linkComponent: (props: { href: string; children: any }) => React.ReactElement;
  requestSetting: RequestFormDto;
}

export const ComponentHostContext = React.createContext<IComponentHost | null>(null);

export function findBlockInfo(blockType: string, formBuilder?: boolean): BlockTypeInfo | null {
  if (formBuilder) {
    for (const info of FormBuilderBlockList) if (info?.id == blockType) return info;
  } else {
    for (const info of AvailableBlocks) if (info?.id == blockType) return info;
  }
  return null;
}

export const BlockPresenter = (props: { blockType: string; blockData: any }) => {
  const info = findBlockInfo(props.blockType) ?? findBlockInfo(props.blockType, true);
  if (info == null) return <div>Unknown block type: {props.blockType}</div>;
  const Renderer = info.renderer;
  return <Renderer {...props.blockData} />;
};

const RowPresenter = (props: PageBlockRowDto) => {
  const sortBlocks = [...props.blocks].sort(function (x, y) {
    return x.type === "breadcrumbsBlock" ? -1 : y.type === "breadcrumbsBlock" ? 1 : 0;
  });
  const findSkip = [...props.blocks].findIndex((x) => x.type === "skipHistoryBlock");

  return (
    <div className={`w-full`} style={{ background: props.background }}>
      <section
        style={{
          background: props.background,
          alignItems: props.vertical,
          maxWidth: props.maxWidth ? props.maxWidth : "100%",
        }}
        className={`relative ${props.marginAuto ? "" : "mx-auto"} flex-wrap flex`}
      >
        {sortBlocks.map((cell, i) => {
          if (cell.hide) return;
          if (props.hideHistory && i >= findSkip && findSkip !== -1) return;
          if (cell.type === "breadcrumbsBlock") return <BlockPresenter blockType={cell.type} blockData={cell.data} />;
          console.log(sortBlocks);
          return (
            <div
              key={i}
              className={cn(
                i === 1 && sortBlocks[0].type == "breadcrumbsBlock" ? "block-with-breadcrumbs" : "",
                `inline-block ${grid["col-" + cell.size]} box-border mx-auto`
              )}
              style={{
                verticalAlign: "top",
                backgroundColor: props.background ? props.background : "",
              }}
            >
              <BlockPresenter blockType={cell.type} blockData={cell.data} />
            </div>
          );
        })}
      </section>
    </div>
  );
};

export const RowsPresenter = (props: { rows: PageBlockRowDto[]; hideHistory?: boolean; marginAuto?: boolean }) => {
  return (
    <>
      {props.rows.map(
        (row, ind) =>
          !row.hide && (
            <React.Fragment key={ind}>
              <RowPresenter
                marginAuto={props.marginAuto}
                blocks={row.blocks}
                maxWidth={row.maxWidth}
                background={row.background}
                hide={row.hide}
                vertical={row.vertical}
                hideHistory={props.hideHistory}
              />
            </React.Fragment>
          )
      )}
    </>
  );
};
