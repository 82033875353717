import React, { useContext } from "react";
import styles from "./AdditionalServicesBlock.module.css"
import { TypedBlockTypeInfo } from "../blocks-info";
import preview from "./preview.png";
import { ApiBaseUrl } from "../../api/apiClientBase";
import cn from "classnames";
import { ComponentHostContext } from "../index";

export interface AdditionalServicesBlockProps {
	title: string;
	subtitle: string;
	highlightedText: string;
	text: string;
	services: {
		serviceIcon: number | null;
		serviceTitle: string;
		serviceText: string;
		backgroundImg: number | null;
		servicePrice: string;
		serviceButtonText: string;
		textAndButtonColor: string
	}[];
}

const normalizeText = (text: string): string => {
	return text.toLowerCase().replace(/\s+/g, ' ').trim();
};

const highlightMatch = (subtitle: string, redtext: string): JSX.Element[] => {
	if (!redtext) return [<span key="0">{subtitle}</span>];
	const normalizedSubtitle = normalizeText(subtitle);
	const normalizedRedtext = normalizeText(redtext);
	const regex = new RegExp(`(${normalizedRedtext.split(' ').join('\\s*')})`, "gi");
	const parts = normalizedSubtitle.split(regex);
	let originalIndex = 0;
	return parts.map((part, index) => {
		const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
		originalIndex += part.length;
		return regex.test(part) ? (
			<span key={index} style={{ backgroundColor: "rgba(206, 225, 243, 1)", color: "rgba(0, 48, 157, 1)" }}>
				{originalPart}
			</span>
		) : (
			<span key={index}>{originalPart}</span>
		);
	});
};

export const AdditionalServicesBlock: React.FC<AdditionalServicesBlockProps> = ({ title, subtitle, highlightedText, text, services }) => {
	const textColor = (color: string) => {
		return color === "black" ? ["#373737", "#FFFFFF"] : ["#FFFFFF", "#373737"];
	};
	const cl = useContext(ComponentHostContext);
	return (
		<div className={cn("py-12 align-center", styles.additionalServicesBlock)}>
			<div className={styles.additionalServicesBlock__title + " text-center mb-6"}>
				{title}
			</div>
			<div className={styles.additionalServicesBlock__subtitle + " text-center md:mb-6 mb-0"}>
				{highlightMatch(subtitle, highlightedText)}
			</div>
			<div className={cn("text-center md:mb-6 mb-0 mx-auto", styles.additionalServicesBlock__text)}>
				{text}
			</div>
			<div className={"flex flex-col justify-between max-w-screen-xl w-full gap-5 my-0 mx-auto py-6 px-2 md:px-8"}>
				<div className={cn(styles.additionalServicesBlock__card, "flex flex-col justify-between p-7 relative overflow-hidden")}>
					<div
						className={cn(styles.additionalServicesBlock__cardImageWrapper, "absolute inset-0")}
					>
						<div
							className={cn(styles.additionalServicesBlock__cardImage)}
							style={{
								backgroundImage: `url(${ApiBaseUrl}/api/media/${services[0].backgroundImg})`,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								width: '100%',
								height: '100%',
							}}
						/>
					</div>
					<div className="flex flex-col justify-between flex-grow relative z-10">
						<div>
							<div className={styles.additionalServicesBlock__imageTitle}>
								{services[0].serviceTitle}
							</div>
							<div className={styles.additionalServicesBlock__imageText}>
								{services[0].serviceText}
							</div>
						</div>
						<div>
							<div className={styles.additionalServicesBlock__imagePrice}>
								{services[0].servicePrice}
							</div>
							<button
								className={styles.additionalServicesBlock__imageButtonFirst}
								style={{
									backgroundColor: textColor(services[0].textAndButtonColor)[0],
									color: textColor(services[0].textAndButtonColor)[1],
								}}
								onClick={() => cl?.showContactUsFormServiceBlue()}
							>
								{services[0].serviceButtonText}
							</button>
						</div>
					</div>
				</div>
				<div style={{
					backgroundImage: `url(${ApiBaseUrl}/api/media/${services[1].backgroundImg})`,
					color: textColor(services[1].textAndButtonColor)[0]
				}}
					className={cn(styles.additionalServicesBlock__firstItem, styles.additionalServicesBlock__card, "hidden md:flex lg:hidden flex-col justify-between p-7")}>
					<div>
						<div className={styles.additionalServicesBlock__imageTitle}>
							{services[1].serviceTitle}
						</div>
						<div className={styles.additionalServicesBlock__imageText}>
							{services[1].serviceText}
						</div>
					</div>
					<div>
						<div className={styles.additionalServicesBlock__imagePrice}>
							{services[1].servicePrice}
						</div>
						<button className={styles.additionalServicesBlock__imageButtonFirst} style={{ backgroundColor: textColor(services[0].textAndButtonColor)[0], color: textColor(services[0].textAndButtonColor)[1] }} onClick={() => cl?.showContactUsFormServiceBlue()}>
							{services[1].serviceButtonText}
						</button>
					</div>
				</div>
				<div style={{
					backgroundImage: `url(${ApiBaseUrl}/api/media/${services[2].backgroundImg})`,
					color: textColor(services[2].textAndButtonColor)[0]
				}}
					className={cn(styles.additionalServicesBlock__firstItem, styles.additionalServicesBlock__card, "hidden md:flex lg:hidden flex-col justify-between p-7")}>
					<div>
						<div className={styles.additionalServicesBlock__imageTitle}>
							{services[2].serviceTitle}
						</div>
						<div className={styles.additionalServicesBlock__imageText}>
							{services[2].serviceText}
						</div>
					</div>
					<div>
						<div className={styles.additionalServicesBlock__imagePrice}>
							{services[2].servicePrice}
						</div>
						<button className={styles.additionalServicesBlock__imageButtonFirst} style={{ backgroundColor: textColor(services[0].textAndButtonColor)[0], color: textColor(services[0].textAndButtonColor)[1] }} onClick={() => cl?.showContactUsFormServiceBlue()}>
							{services[2].serviceButtonText}
						</button>
					</div>
				</div>
				<div className={cn(styles.additionalServicesBlock__secondRow, "w-full hidden lg:grid grid-cols-2 gap-5")}>
					{services.slice(1, services.length).map((service, index) => (
						<div
							key={index}
							className={cn("flex flex-col justify-between p-7 relative overflow-hidden", styles.additionalServicesBlock__secItem, styles.additionalServicesBlock__card)}
							style={{ backgroundImage: `url(${ApiBaseUrl}/api/media/${service.backgroundImg})`, color: textColor(service.textAndButtonColor)[0] }}
						>
							<div
								className={cn(styles.additionalServicesBlock__cardImageWrapper, "absolute inset-0")}
							>
								<div
									className={cn(styles.additionalServicesBlock__cardImage)}
									style={{
										backgroundImage: `url(${ApiBaseUrl}/api/media/${service.backgroundImg})`,
										backgroundSize: 'cover',
										backgroundPosition: 'center',
										width: '100%',
										height: '100%',
									}}
								/>
							</div>
							<div className="flex flex-col justify-between flex-grow relative z-10">
								<div>
									<img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={cn(styles.serviceIcon, index % 2 === 0 ? "flex md:hidden" : "lg:hidden")} alt="" />
									<div className={styles.additionalServicesBlock__imageTitle}>
										{service.serviceTitle}
									</div>
									<div className={styles.additionalServicesBlock__imageText}>
										{service.serviceText}
									</div>
								</div>
								<div>
									<div className={styles.additionalServicesBlock__imagePrice}>
										{service.servicePrice}
									</div>
									<button
										className={styles.additionalServicesBlock__imageButton}
										style={{ backgroundColor: textColor(service.textAndButtonColor)[0], color: textColor(service.textAndButtonColor)[1], zIndex: 30, position: 'relative', }}
										onClick={() => cl?.showContactUsFormServiceBlue()}
									>
										{service.serviceButtonText}
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
				{/*<div className={styles.additionalServicesBlock__rightItem + ""} style={{backgroundImage: `url(${ApiBaseUrl}/api/media/${services[3].backgroundImg})`}}>*/}
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
					{services.slice(1, services.length).map((service, index) => (
						<div key={index} className={cn(styles.additionalServicesBlock__miniItem, styles.additionalServicesBlock__card, "flex-col justify-between p-7 flex md:hidden")}>
							<div className="text-content">
								<img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
								<div className={styles.additionalServicesBlock__imageTitle} style={{
									width: service.serviceTitle.length < 30 ? '60%' : '90%'
								}}>
									{service.serviceTitle}
								</div>
								<div className={cn(styles.additionalServicesBlock__imageText, styles.styleText, "flex")}>
									{service.serviceText}
								</div>
							</div>
							<div>
								<div className={styles.additionalServicesBlock__imagePrice}>
									{service.servicePrice}
								</div>
								<button
									className={styles.additionalServicesBlock__imageButton}
									style={{
										backgroundColor: "#373737",
										color: "#FFFFFF",
										zIndex: 30,
										position: 'relative',
									}}
									onClick={() => cl?.showContactUsFormServiceBlue()}
								>
									{service.serviceButtonText}
								</button>
							</div>
						</div>
					))}
					{services.slice(3, services.length).map((service, index) => (
						<div key={index} className={cn(styles.additionalServicesBlock__miniItem, styles.additionalServicesBlock__card, "flex-col justify-between p-7 hidden md:flex lg:hidden")}>
							<div className="text-content">
								<img src={`${ApiBaseUrl}/api/media/${service.serviceIcon}`} className={styles.serviceIcon} alt="" />
								<div className={styles.additionalServicesBlock__imageTitle} style={{
									width: service.serviceTitle.length < 30 ? '60%' : '90%'
								}}>
									{service.serviceTitle}
								</div>
								<div className={cn(styles.additionalServicesBlock__imageText, styles.styleText, "flex")}>
									{service.serviceText}
								</div>
							</div>
							<div>
								<div className={styles.additionalServicesBlock__imagePrice}>
									{service.servicePrice}
								</div>
								<button
									className={styles.additionalServicesBlock__imageButton}
									style={{
										backgroundColor: "#373737",
										color: "#FFFFFF",
										zIndex: 30,
										position: 'relative',
									}}
									onClick={() => cl?.showContactUsFormServiceBlue()}
								>
									{service.serviceButtonText}
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
};

export const AdditionalServicesBlockInfo: TypedBlockTypeInfo<AdditionalServicesBlockProps> = {
	id: "AdditionalServicesBlock",
	name: "AdditionalServicesBlock",
	preview: preview,
	renderer: AdditionalServicesBlock,
	initialData: {
		title: "string",
		subtitle: "string",
		highlightedText: "string",
		text: "string",
		services: [
			{
				serviceIcon: null,
				serviceTitle: "string",
				serviceText: "string",
				backgroundImg: null,
				servicePrice: "string",
				serviceButtonText: "string",
				textAndButtonColor: "black"
			},
		],
	},
	definition: {
		subTypes: {
			service: {
				fields: [
					{
						id: "serviceIcon",
						name: "Service Icon",
						type: "Custom",
						customType: "Image",
					},
					{
						id: "serviceTitle",
						name: "Service Title",
						type: "String",
					},
					{
						id: "serviceText",
						name: "Service Text",
						type: "String",
					},
					{
						id: "backgroundImg",
						name: "Background Image",
						type: "Custom",
						customType: "Image",
					},
					{
						id: "servicePrice",
						name: "Service Price",
						type: "String",
					},
					{
						id: "serviceButtonText",
						name: "Service Button Text",
						type: "String",
					},
					{
						id: "textAndButtonColor",
						name: "Text And Button Color",
						type: "Radio",
						possibleValues: [
							{
								id: "black",
								name: "black"
							},
							{
								id: "white",
								name: "white"
							}
						]
					},
				],
			},
		},
		fields: [
			{
				id: "title",
				type: "String",
				name: "Title",
			},
			{
				id: "subtitle",
				type: "String",
				name: "Subtitle",
			},
			{
				id: "highlightedText",
				type: "String",
				name: "Highlighted Text",
			},
			{
				id: "text",
				type: "String",
				name: "Text",
			},
			{
				id: "services",
				name: "Services",
				type: "List",
				listType: "service",
			},
		],
	},
};
