"use client";

import { YMapMarkerProps, YMapProps, YMap as YMapType, LngLat } from "@yandex/ymaps3-types";
import React, { PropsWithChildren, ReactNode, forwardRef } from "react";
import { useMapComponents } from "../../utils/MapComponentsProvider";
import cn from "classnames";
import styles from "./Map.module.css";

export interface MapProps extends YMapProps, PropsWithChildren<any> {
  isGeolocationEnabled?: boolean;
  onGeolocatePosition?: (posititon: LngLat) => void;
  children?: ReactNode;
}

export type MapRef = YMapType;

export * from "../../utils/MapComponentsProvider";

export const Map = forwardRef<MapRef, MapProps>(
  ({ children, className, isGeolocationEnabled = true, onGeolocatePosition, ...props }, ref) => {
    const components = useMapComponents();

    if (!components) {
      return null;
    }

    const {
      YMap,
      YMapControls,
      YMapDefaultFeaturesLayer,
      YMapDefaultSchemeLayer,
      YMapGeolocationControl,
      YMapZoomControl,
    } = components;

    return (
      <div className={cn("relative overflow-hidden", styles.map, className)}>
        <YMap {...props} ref={ref}>
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />

          <YMapControls orientation={"vertical"} position={"right"}>
            {isGeolocationEnabled ? <YMapGeolocationControl onGeolocatePosition={onGeolocatePosition} /> : null}
            <YMapZoomControl />
          </YMapControls>

          {children}
        </YMap>
      </div>
    );
  }
);

Map.displayName = "Map";

export function MapPoint(props: YMapMarkerProps) {
  const components = useMapComponents();

  if (!components) {
    return null;
  }

  const { YMapMarker } = components;

  return (
    <YMapMarker zIndex={999} {...props}>
      <div className={styles.marker} />
    </YMapMarker>
  );
}
export default Map;
