import React, { useEffect, useRef, useState } from "react";
import { TypedBlockTypeInfo } from "../blocks-info";
import styles from "./ConsultationWithTextBlock.module.css"
import preview from "./preview.png";
import cn from "classnames";

export interface RequestForConsultationBlockElement {
    title: string,
    highlightedTextTitle: string,
    text: string,
    items: {
        text: string
    }[]
    phonePlaceholder: string,
    emailPlaceHolder: string,
    namePlaceholder: string,
    commentPlaceholder: string,
    buttonText: string,
    buttonDescription: string,
    linkedTextInButtonDescription: string,
    documentLink: string,
    backgroundColor: "pink" | "blue" | "light blue";
}

// export type ContactUsFormPropsType = {
//     onDismiss: () => void;
//     onSuccess: () => void;
// }
//
// type Inputs = {
//     name: string
//     // lastname: string
//     email: string
//     tel: string
//     contactWay: string
//     // com: string
// }

export const ConsultationWithTextBlock = (props: RequestForConsultationBlockElement) => {
    const backgroundColor = props.backgroundColor === "pink" ? "#F3D7E3" : props.backgroundColor === "blue" ? "#00309D" : "#CEE1F3"
    const descriptionColor = props.backgroundColor === "blue" ? "#C3C3C3" : "#6E6E6E"
    const startIndex = props.buttonDescription.lastIndexOf(props.linkedTextInButtonDescription);
    const titleColor =
        props.backgroundColor === "blue"
            ? "#FFFFFF"
            : "#000000";

    let beforeText = props.buttonDescription;
    let highlightedText = '';

    if (startIndex !== -1 && startIndex + props.linkedTextInButtonDescription.length === props.buttonDescription.length) {
        beforeText = props.buttonDescription.substring(0, startIndex);
        highlightedText = props.linkedTextInButtonDescription;
    }

    const normalizeText = (text: string): string => {
        return text.toLowerCase().replace(/\s+/g, ' ').trim();
    };

    const highlightMatch = (subtitle: string, redtext: string): JSX.Element[] => {
        if (!redtext) return [<span key="0">{subtitle}</span>];
        const normalizedSubtitle = normalizeText(subtitle);
        const normalizedRedtext = normalizeText(redtext);
        const regex = new RegExp(`(${normalizedRedtext.split(' ').join('\\s*')})`, "gi");
        const parts = normalizedSubtitle.split(regex);
        const highlightColor = props.backgroundColor === "blue" ? "#CEE1F3" : props.backgroundColor === "light blue" ? "#00309D" : "#FA5354"
        const highlightTextColor = props.backgroundColor === "blue" ? "#00309D" : "#FFFFFF"

        let originalIndex = 0;

        return parts.map((part, index) => {
            const originalPart = subtitle.substring(originalIndex, originalIndex + part.length);
            originalIndex += part.length;

            return regex.test(part) ? (
                <span key={index} style={{ backgroundColor: `${highlightColor}`, color: `${highlightTextColor}` }}>
                    {originalPart}
                </span>
            ) : (
                <span key={index}>{originalPart}</span>
            );
        });
    };

    // const {
    //     register,
    //     handleSubmit,
    //     formState: { errors, dirtyFields },
    //
    // } = useForm<Inputs>({
    //     mode: 'onChange'
    // })

    // const onSubmit: SubmitHandler<Inputs> = async (data) => {
    //     // const atIndex = data.email.indexOf("@");
    //     // if (contactWay.id <= -1) {
    //     //     return;
    //     // }
    //     // if (atIndex == -1) {
    //     //   data.email = `${data.email}${selectedDomain}`;
    //     // }
    //     try {
    //         await siteApi.sendCallback({
    //             firstName: data.name,
    //             // lastName: data.lastname,
    //             email: data.email,
    //             phone: data.tel,
    //             // comment: data.com,
    //             // contactWay: contactWay.id,
    //             contactWay: 1,
    //             // url: window.location.href,
    //         });
    //         props.onSuccess ? props.onSuccess() : null;
    //     } catch (e) {
    //         alert(e);
    //     }
    // }

    const [isVisible, setIsVisible] = useState(false);
    const blockRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, []);

    const getFadeInClass = (delay: number) => ({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: `opacity 1.5s ease-out ${delay}s, transform 1.5s ease-out ${delay}s`
    });
    return (
        <div ref={blockRef} style={{ backgroundColor: backgroundColor }} className={cn(styles.requestForConsultation, "flex justify-center")}>
            <div className={cn("h-full w-full max-w-screen-xl flex flex-col-reverse lg:flex-row gap-10 lg:gap-0", {
                [styles.requestForConsultationVisible]: isVisible,
            })}>
                <div className={cn(styles.requestForConsultation__form)}>
                    <form
                        className={"flex flex-col gap-2"}
                        // onSubmit={handleSubmit(onSubmit)}
                        onSubmit={event => event.preventDefault()}
                    >
                        <div className={"flex flex-col gap-2"} style={getFadeInClass(0.1)}>
                            {/*<div className={"flex flex-col justify-between mb-0 md:mb-2"}>*/}
                            {/*<div className={"flex flex-col lg:w-6/12 lg:mr-0.5"}>*/}
                            <label className={"w-full mb-1"}>
                                <input
                                    className={cn(styles.requestForConsultation__input, "w-full", {
                                        // 'error': errors.name,
                                        // 'correct': !errors.name && dirtyFields.name
                                    })}
                                    //{...register('name', { required: true })}
                                    placeholder={props.namePlaceholder}
                                />
                            </label>
                            {/*</div>*/}
                            {/*<div className={"flex flex-col lg:w-6/12 lg:ml-0.5"}>*/}
                            <label className={"w-full mb-1"}>
                                <input
                                    className={cn(styles.requestForConsultation__input, "w-full", {
                                        // 'error': errors.tel,
                                        // 'correct': !errors.tel && dirtyFields.tel
                                    })}
                                    //*{...register('tel', { required: true, pattern: /^\+?[0-9]{10,}$/ })}
                                    placeholder={props.phonePlaceholder}
                                />
                            </label>
                            <label className={"w-full mb-1"}>
                                <div className="flex items-center">
                                    <input
                                        className={cn(styles.requestForConsultation__input, "w-full", {
                                            // 'error': errors.email,
                                            // 'correct': !errors.email && dirtyFields.email
                                        })}
                                        // onKeyUp={handleKeyUp}
                                        //{...register('email', { required: true})}
                                        placeholder={props.emailPlaceHolder}
                                    />
                                </div>
                            </label>
                            <label className={"w-full mb-1"}>
                                <div className="flex items-center">
                                    <textarea
                                        className={cn(styles.requestForConsultation__input, "w-full", {
                                            // 'error': errors.email,
                                            // 'correct': !errors.email && dirtyFields.email
                                        })}
                                        // onKeyUp={handleKeyUp}
                                        //{...register('email', { required: true})}
                                        placeholder={props.commentPlaceholder}
                                    />
                                </div>
                            </label>
                        </div>
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className={"flex flex-col mt-3 mb:mt-11"}>*/}
                        <button className={cn(styles.requestForConsultation__button, "flex self-start")} type="submit" style={getFadeInClass(0.2)}>
                            {props.buttonText}
                        </button>
                        {/*</div>*/}
                    </form>
                    <div style={{ color: `${descriptionColor}`, ...getFadeInClass(0.3)}} className={cn(styles.requestForConsultation__description)}>
                        {beforeText}
                        {highlightedText && (
                            <a href={props.documentLink} style={{ textDecoration: "underline" }}>{highlightedText}</a>
                        )}
                    </div>
                    {/*<button type="submit">*/}
                    {/*    /!*<LocalizedText id="contactUs_applyButton" />*!/*/}
                    {/*</button>*/}
                </div>
                <div className={cn("flex flex-col gap-5 lg:gap-8 justify-center", styles.requestForConsultation__text)} style={getFadeInClass(0.4)}>
                    <div className={styles.requestForConsultation__title} style={{ color: titleColor }}>{highlightMatch(props.title, props.highlightedTextTitle)}</div>
                    <div className={cn(styles.requestForConsultation__subtitle)} style={{ color: titleColor }}>{props.text}</div>
                    <ul style={{ color: titleColor }} className={cn(styles.requestForConsultation__ul, "flex flex-col gap-3")}>
                        {props.items.map((item) =>
                            <li className={cn("ml-3")}>
                                {item.text}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>

    );
};

export const ConsultationWithTextBlockInfo: TypedBlockTypeInfo<RequestForConsultationBlockElement> = {
    id: "ConsultationWithTextBlock",
    name: "ConsultationWithTextBlock",
    preview: preview,
    renderer: ConsultationWithTextBlock,
    initialData: {
        title: "string",
        highlightedTextTitle: "",
        text: "string",
        items: [{ text: "string" }],
        phonePlaceholder: "string",
        emailPlaceHolder: "string",
        namePlaceholder: "string",
        commentPlaceholder: "string",
        buttonText: "string",
        buttonDescription: "string",
        linkedTextInButtonDescription: "#",
        documentLink: "string",
        backgroundColor: "pink",
    },
    definition: {
        subTypes: {
            item: {
                fields: [
                    {
                        id: "text",
                        type: "String",
                        name: "Text",
                    }
                ]
            }
        },
        fields: [
            {
                id: "title",
                type: "String",
                name: "Title",
            },
            {
                id: "highlightedTextTitle",
                type: "String",
                name: "Highlighted Text Title",
            },
            {
                id: "text",
                type: "String",
                name: "Text",
            },
            {
                id: "items",
                type: "List",
                listType: "item",
                name: "Items"
            },
            {
                id: "phonePlaceholder",
                type: "String",
                name: "Phone Placeholder",
            },
            {
                id: "emailPlaceHolder",
                type: "String",
                name: "Email PlaceHolder",
            },
            {
                id: "namePlaceholder",
                type: "String",
                name: "Name Placeholder",
            },
            {
                id: "commentPlaceholder",
                type: "String",
                name: "Comment Placeholder",
            },
            {
                id: "buttonText",
                type: "String",
                name: "Button Text",
            },
            {
                id: "buttonDescription",
                type: "String",
                name: "Button Description",
            },
            {
                id: "linkedTextInButtonDescription",
                type: "String",
                name: "Linked Text In Button Description",
            },
            {
                id: "documentLink",
                type: "String",
                name: "Document Link",
            },
            {
                id: "backgroundColor",
                type: "Radio",
                name: "Background Color",
                possibleValues: [
                    {
                        id: "pink",
                        name: "pink",
                    },
                    {
                        id: "blue",
                        name: "blue",
                    },
                    {
                        id: "light blue",
                        name: "light blue",
                    },
                ],
            },
        ],
    },
};
